<template>
  <div>

    <el-descriptions direction="vertical" :column="4" border>
      <el-descriptions-item label="标题">第一个通知</el-descriptions-item>
      <el-descriptions-item label="发信人">管理员</el-descriptions-item>
      <el-descriptions-item label="信息类型">系统通知</el-descriptions-item>
      <el-descriptions-item label="信息日期">2022-06-09</el-descriptions-item>
      <el-descriptions-item label="内容">
        尊敬的用户您好:

        心狗健康致力于研发人体健康领域内的各种便携式终端产品，软件和系统，感谢您对心狗产品和服务的支持和信任，我们一直在努力，让用户获得更便携可及、优质有效与值得信赖的医疗健康服务。

        在推出心狗Android版和iOS版软件后，目前我们提供了用户自己的信息管理平台——心狗健康管理系统，用户在手机端测到的数据将同步上传到云上，用户可以在心狗健康管理系统中查看和管理这些数据，并了解自己和亲友的健康状况，在这里我们具体提供的服务有:
      </el-descriptions-item>
    </el-descriptions>

    <el-button  size="mini" @click="$router.push({ name: 'writeMessage1' })" type="primary" style="margin-top: 25px;font-size: 15px">
      回信
    </el-button>

  </div>
</template>

<script>
export default {
  name: "systemMessage1"
}
</script>

<style scoped>

</style>
